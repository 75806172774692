import React from "react"

import * as styles from "./videos.module.css"

let Videos = () => {

    
  return (
    <div className={styles.videos}>
      <button className={styles.button}>Test</button>
      <button className={styles.button}>Testaa</button>
      <button className={styles.button}>Testbbbbbb</button>
      <button className={styles.button}>Test</button>
      <button className={styles.button}>Test</button>
      <button className={styles.button}>Test</button>
      <button className={styles.button}>Test</button>
      <button className={styles.button}>Test</button>
      <button className={styles.button}>Test</button>
     

    </div>
  )
}

export default Videos
